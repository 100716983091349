<template>
  <div class="editor-index">
    <div class="con-item">
      <div class="container">
        <div class="news-menu editor-menu">
          <a-card title="编辑风采" :bordered="false">
            <a-menu
              :selectedKeys="defaultTitle"
              theme="light"
              mode="inline"
              @select="changeMenu"
            >
              <template v-for="item in titleList">
                <a-menu-item v-if="!item.childrenList" :key="item.id">
                  <i></i>
                  <span>{{ item.themename }}</span>
                </a-menu-item>
                <Sub-menu v-else :key="item.id" :menu-info="item" />
              </template>
            </a-menu>
          </a-card>
        </div>
        <div class="list">
          <a-list item-layout="vertical" :data-source="dataEditor">
            <a-list-item slot="renderItem" key="item.title" slot-scope="item">
              <img
                slot="extra"
                alt="pic"
                v-if="!item.sysFldFilepath"
                @click="goDetails(item.sysFldDoi)"
                :src="require('@/assets/images/editor.jpg')"
              />
              <img
                slot="extra"
                alt="pic"
                v-if="item.sysFldFilepath"
                @click="goDetails(item.sysFldDoi)"
                :src="item.imgurl"
              />
              <a-list-item-meta>
                <template slot="title">{{ item.position }}</template>
                <a slot="description" @click="goDetails(item.sysFldDoi)">
                  <span class="hover-name" v-html="item.name"></span>
                </a>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
          <div class="page-other">
            <a-pagination
              show-quick-jumper
              v-show="total > 9"
              v-model="current"
              :total="total"
              :page-size="9"
              @change="onChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from "../components/hearMenu.vue";
import * as api from "@/services/newsList.js";
import * as apiedit from "@/services/editorIndex.js";
import { replaceNew } from "@/utils/util";

export default {
  name: "EditorIndex",
  components: { SubMenu },
  data() {
    return {
      dataEditor: [],
      defaultTitle: [],
      titleList: [],
      current: 1,
      total: 0,
      searchValue:''
    };
  },
  created() {
    //查询新闻栏目列表
    this.getAllLevelTheme();
  },
  methods: {
    searchEditor(value) {
      this.searchValue = value
      this.current = 1
      this.getNoteList(this.defaultTitle[0])
    },
    //跳转详情
    goDetails(doi) {
      this.$router.push({
        name: "EditorDetails",
        params: {
          doi: doi,
        },
      });
    },
    changeMenu(item) {
      this.defaultTitle = [item.key];
      this.current = 1;
      //查询当前栏目下的编辑
      this.getNoteList(this.defaultTitle[0]);
    },
    getAllLevelTheme() {
      if (this.titleList && this.titleList.length > 0) {
        if (!this.$route.params.id) {
          this.defaultTitle = [this.titleList[0].id];
        } else {
          this.defaultTitle = [this.$route.params.id];
        }
        this.current = 1;
        //查询当前栏目下的新闻列表
        this.getNoteList(this.defaultTitle[0]);
      } else {
        api.getAllLevelTheme("12.").then((res) => {
          if (res.data.success) {
            this.titleList = res.data.data;
            this.titleList.unshift({
              id:'全部',
              ordernum:'',
              parentid:'',
              themename:'全部'
            })
            if (!this.$route.params.id) {
              this.defaultTitle = [this.titleList[0].id];
            } else {
              this.defaultTitle = [this.$route.params.id];
            }
            this.current = 1;
            //查询当前栏目下的新闻列表
            this.getNoteList(this.defaultTitle[0]);
          }
        });
      }
    },
    onChange(number) {
      this.current = number;
      this.getNoteList(this.defaultTitle[0]);
    },
    getNoteList(id) {
      let obj = {
        id: id==='全部'?'':id,
        pageNumber: this.current,
        pageSize: 9,
        query:this.searchValue
      };
      let vm = this
      apiedit.getEditorList(obj).then((res) => {
        if (res.data.success) {
          this.dataEditor = res.data.data.list;
          this.dataEditor.map(function (item) {
            item.name = replaceNew(item.name, vm.searchValue);
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldFilepath;
          });
          this.total = Number(res.data.data.count);
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "./EditorIndex.less";
</style>