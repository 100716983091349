import {
    request,
    METHOD
} from '@/utils/request'
import {
    addurl
} from '@/utils/util'
import {
    baseURL
} from '@/config/proxyconfig'

const requesturl = baseURL + '/api/editor/'
//查询编辑
export async function getEditorList(params) {
    return request(addurl('getEditorList', requesturl) + '/' + params.pageNumber + '/' + params.pageSize + '?orgId=' + params.id + '&query=' + params.query,
        METHOD.GET)
}
//
// 作者详情页面-->根据作者id展示图书列表和作者详细信息
export async function getEditorInfo(params) {
    return request(addurl('getEditorInfo', requesturl) + '/' + params.pageNumber + '/' + params.pageSize + '/' + params.doi,
        METHOD.GET)
}


export default {
    getEditorList,
}